import React from "react"
import { Container, Flex, Section } from "../layoutComponents"
import { List, ImgWrapper } from "./serviceItems"
import Title from "../title"

import ResidentialServices from "../../images/services/residential-services.png"

export default function Residential(props) {
  return (
    <Section>
      <Container>
        <Title title="Residential Electrician Toronto and GTA" />
        <Flex>
          <List className="spacing">
            <li>Complete service upgrades</li>
            <li>New construction and renovations</li>
            <li>Knob and Tube Rewires</li>
            <li>Complete lighting solutions</li>
            <li>Hot tubs, pools and spas</li>
            <li>Complete generator installations and more</li>
          </List>
          <ImgWrapper>
            <img
              src={ResidentialServices}
              alt="residential electrical services ontario"
            />
          </ImgWrapper>
        </Flex>
      </Container>
    </Section>
  )
}
