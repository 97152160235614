import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerActions from "../../components/banners/bannerActions"
import Residential from "../../components/services/residential"
import FeaturedProject from "../../components/gallery/featuredProject"
import CallToAction from "../../components/banners/callToAction"
import FeaturedTestimonial from "../../components/testimonials/featuredTestimonial"

import Featured from "../../images/projects/residential/100a-apartment-load-centre.jpg"
import Banner from "../../images/banner.png"
import ActionBanner from "../../images/action-banner.png"

export default function ResidentialServices() {
  return (
    <Layout>
      <SEO title="Residential Electrician Toronto and GTA" />
      <BannerActions img={Banner} />
      <Residential />
      <CallToAction
        img={ActionBanner}
        textPrimary="need a residential electrician?"
        textSecondary=""
      />
      <FeaturedProject
        title="recent residential project"
        description="100A Apartment Load Centre"
        img={Featured}
        altText="Residential Electrician Toronto and GTA"
      />
      <FeaturedTestimonial
        testimonial="James Ross of Royco Electric has a great team of qualified electricians. They have handled a lot of my projects from basements, main floor and kitchen renovations. If I need a service changed to 200 amps they could handle it. They work with efficiency and projects are always done within the time frame specified. Inspections are always passed without deficiencies. Royco always leaves the jobsite clean and tidy. I give Royco Electric five stars."
        company="Finished Basements"
        name="JEROME SAMMY"
      />
    </Layout>
  )
}
